import HomePage from '@/pages/HomePage.vue'
import UnosPodatakaPage from '@/pages/UnosPodataka.vue'
import UnosPrigovoraPage from '@/pages/UnosPrigovora.vue'
import SlanjePrigovoraPage from '@/pages/SlanjePrigovoraPage.vue'
import SlanjeErrorPage from '@/pages/SlanjeErrorPage.vue'

export default [
    {
        path: "/",
        name: 'Home',
        component: HomePage
    },
    {
        path: "/unos-podataka",
        component: UnosPodatakaPage,
        name: 'UnosPodataka'
    },
    {
        path: "/unos-prigovora",
        component: UnosPrigovoraPage,
        name: 'UnosPrigovora'
    },
    {
        path: "/slanje-prigovora",
        component: SlanjePrigovoraPage,
        name: 'SlanjePrigovora'
    },
    {
        path: "/greska-prigovora",
        component: SlanjeErrorPage,
        name: 'ErrorPrigovora'
    }
]