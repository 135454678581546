<script setup>
import { defineProps, watch } from "vue";
import { useCurrencyInput, CurrencyDisplay } from "vue-currency-input";

const props = defineProps({ modelValue: Number, rules: Object });

const { inputRef, formattedValue, setValue } = useCurrencyInput({
  locale: "de-DE",
  currency: "EUR",
  currencyDisplay: CurrencyDisplay.hidden,
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  precision: 2,
  valueRange: { min: 0 },
});

watch(
  () => props.modelValue,
  (value) => {
    setValue(value);
  }
);
</script>

<template>
  <v-text-field
    v-model="formattedValue"
    density="compact"
    variant="solo"
    ref="inputRef"
    :rules="rules"
  ></v-text-field>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
    class="v-icon--fill-bright-blue v-icon--position-right v-form-input__icon iznos-transakcije-svg"
  >
    <g size="16">
      <path
        d="M14.5 15H.5a.5.5 0 0 0 0 1h14a.5.5 0 0 0 0-1ZM.5 13.5h.12l4-1a.49.49 0 0 0 .23-.14l8.5-8.5a.48.48 0 0 0 0-.7l-3-3a.48.48 0 0 0-.7 0l-8.5 8.5a.41.41 0 0 0-.13.23l-1 4a.46.46 0 0 0 .13.47.47.47 0 0 0 .35.14ZM10 1.21l2.29 2.29L11 4.79 8.71 2.5ZM2 9.26l6.05-6 2.24 2.24-6 6.05-3.05.76Z"
      ></path>
    </g>
  </svg>
</template>
