import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import './assets/main.css'
import "vue-select/dist/vue-select.css";
import { createApp } from 'vue'
// import VueMask from 'v-mask'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import routes from './routes'


const app = createApp(App);
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


app.use(router);

// Vuetify
const vuetify = createVuetify({
  components,
  directives,
})

// app.use(VueMask);
app.use(vuetify);



// app.component("v-select", vSelect);

app.mount('#app')
